<template>
  <v-card elevation="0" rounded="lg" outlined>
    <v-card-text>
      <v-tabs color="primary" v-model="tabSection">
        <v-tab class="text-capitalize">Detail</v-tab>
        <v-tab class="text-capitalize">Informasi Program</v-tab>
        <v-tab class="text-capitalize">Fasilitator</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabSection">
        <v-tab-item>
          <v-row class="mt-5">
            <v-col cols="12">
              <h3 class="mb-1">Program</h3>              
            </v-col>
            <v-col cols="12">
              <div class="mb-2">Judul Program</div>
              <v-text-field filled hide-details="auto" v-model="detail.title" placeholder="Masukan judul program" class="border-0"></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="mb-2">Deskripsi Program</div>
              <!-- <v-textarea
                rows="3"
                no-resize                
                filled
                hide-details
                placeholder="Masukan manfaat program"
                v-model="detail.program_benefits"
              ></v-textarea> -->
              <editor
                      api-key="4paqvfu388didkn8yr5v662i655cn9x3g5w1usf7ucmypuoq"
                      v-model="detail.program_benefits"
                      placeholder="Masukan deskripsi program"
                      :init="{
                         height: 350,
                         menubar: false,
                         plugins: [
                           'advlist autolink lists link image charmap print preview anchor',
                           'searchreplace visualblocks code fullscreen',
                           'insertdatetime media table paste code help wordcount'
                         ],
                         toolbar:
                           'undo redo | formatselect | bold italic backcolor | \
                           alignleft aligncenter alignright alignjustify | \
                           bullist numlist | link table',

                           /* without images_upload_url set, Upload tab won't show up*/
                            images_upload_url: 'postAcceptor.php',

                            /* we override default upload handler to simulate successful upload*/
                            images_upload_handler: function (blobInfo, success, failure) {
                              setTimeout(function () {
                                /* no matter what you upload, we will turn it into TinyMCE logo :)*/
                                success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png');
                              }, 2000);},
                          // tinydrive_demo_files_url: '/docs/demo/tiny-drive-demo/demo_files.json',
                          // tinydrive_token_provider: function (success, failure) {
                          //   success({ token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJqb2huZG9lIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.Ks_BdfH4CWilyzLNk8S2gDARFhuxIauLa8PwhdEQhEo' });
                          // }
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;background:#f4f6f9; }'
                        }"
                    />    
            </v-col>
            <!-- <v-col cols="12">
              <h3 class="">Sasaran Program Pembelajaran</h3>              
            </v-col>
            <v-col cols="12">
              <div class="mb-2">Jenjang Usia</div>
              <v-select
                :items="jenjang"
                label="Pilih jenjang usia"
                solo
                v-model="learning_targets.age_level"
              ></v-select>
            </v-col> -->
            <v-col cols="12">
              <div class="mb-2">Bidang Studi</div>
              <v-autocomplete
                v-model="study"
                :items="studi_list"
                :item-text="'title'"
                :item-value="`${study.id}, ${study.title}`"
                dense
                return-object
                filled                
                placeholder="Pilih bidang studi"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" v-if="this.study.title === 'Lainnya'">
              <v-text-field filled hide-details="auto" v-model="bidang_studi_add" placeholder="Masukan nama bidang studi"></v-text-field>
            </v-col>

            <!-- <v-col cols="12">
              <div class="mb-2">Bidang Studi</div>
              <v-select
                :items="studi"
                label="Pilih bidang studi"
                solo
                v-model="study"
              ></v-select>
            </v-col>

            <v-col cols="12" v-if="this.study === 'Lainnya'">
              <v-text-field filled hide-details="auto" v-model="learning_targets.studies.title" placeholder="Masukan nama bidang studi"></v-text-field>
            </v-col> -->

            <!-- <v-col cols="12">
              <div class="mb-2">Tujuan Pembelajaran</div>
              <vue-editor v-model="learning_targets.learning_objectives" :editor-toolbar="customToolbar" placeholder="Masukan tujuan pembelajaran"/>
            </v-col> -->

            <v-col cols="12">
              <div class="mb-2">Kompetensi</div>
              <div v-for="(name, index) in list_kompetensi" :key="'A'+index">
                <div class="card mb-2">                  
                  <v-text-field filled hide-details="auto" v-model="list_kompetensi[index]" placeholder="Masukan kompetensi" 
                    :append-outer-icon="'ri-delete-bin-line'"
                    @click:append-outer="deleteKompetensi(name.id || index)"
                    ></v-text-field>
                </div>
              </div>
              <v-col cols="12">
                <v-btn outlined color="primary" @click="list_kompetensi.push('')">
                  <span> <v-icon>$add</v-icon> Tambah kompetensi</span>
                </v-btn>
              </v-col>
            </v-col>                
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row class="mt-2">
            <v-col cols="12">
              <div class="mb-2">Cover Kelas</div>
              <div class="helper"></div>
              <div class="drop display-inline text-center" @dragover.prevent @drop="onDrop">
                  <div v-if="!this.cover_kelas.id" class="content-img pt-12">                    
                    <p class="mb-2 pt-8"><v-icon medium>ri-image-add-line</v-icon></p>
                    <p class="mb-0 subtitle-2">Tambahkan Gambar</p>
                    <p class="mb-2 subtitle-2">dengan</p>
                    <label class="btn-img display-inline text-center">
                      Pilih Berkas
                      <input type="file" name="image" @change="onChange" accept="image/*">
                    </label>
                  </div>                  
                  <div class="hidden display-inline align-center py-4" v-else v-bind:class="{ 'image': true }">
                    <div class="box-img-line " style="width: 100%;margin:auto;position: relative;">                      
                      <img :src="this.cover_kelas.url || this.image" alt="" class="img-cover" />                    
                      <div class="btn-float">                        
                        <label class="fab-white bg-label mx-2">
                          <v-icon dark>
                            ri-arrow-go-back-line
                          </v-icon>
                          <input type="file" name="image" @change="onChange" accept="image/*">
                        </label>
                        <!-- <v-btn small class="mx-2 fab-white" fab @click="removeFile">
                          <v-icon dark>
                            ri-delete-bin-7-line
                          </v-icon>
                        </v-btn> -->
                      </div>
                    </div>
                  </div>
              </div>
              
            </v-col>
            <v-col cols="12" md="4">
              <div class="mb-2">Tipe Program</div>
              <v-select
                :items="type_prog"
                :item-text="'name'"
                :item-value="'id'"
                placeholder="Pilih tipe program"
                v-model="detail.type"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <div class="mb-2">Harga Coret</div>
              <v-text-field filled hide-details="auto" type="number" v-model.number="program_information.discount_price" placeholder="Masukan harga coret" class="" hint="Jika ingin menggunakan Harga coret harus lebih besar dari Harga jual" persistent-hint></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <div class="mb-2">Harga Jual</div>
              <v-text-field filled hide-details="auto" type="number" v-model.number="program_information.selling_price" placeholder="Masukan harga jual" class=""></v-text-field>
            </v-col>
            <!-- <v-col cols="12">
              <div class="mb-2">Link Produk</div>
              <v-text-field filled hide-details="auto" placeholder="Masukan link produk" class="" v-model="program_information.selling_link"></v-text-field>
            </v-col> -->
            <v-col cols="12">
              <div class="mb-2">Link Produk</div>
              <div v-for="(link, index) in link_produk" :key="'L'+index">
                  <div class="d-flex">
                    <v-text-field v-model="link.title" placeholder="Masukkan judul" class="mr-2 mb-2"></v-text-field>
                    <v-text-field v-model="link.url" placeholder="Masukkan link produk" class="mb-2"></v-text-field>
                    <v-btn icon @click="link_produk.splice(index, 1);"><v-icon>$delete</v-icon></v-btn>   
                  </div>
                </div>
                <v-col cols="12">
                  <v-btn outlined @click="link_produk.push({})">+ Tambah link produk</v-btn>
                </v-col>
            </v-col>     
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-expansion-panels focusable class="mt-2" v-model="panel" multiple>
            <v-expansion-panel v-for="(fasilitator, index) in list_fasilitator" cols="12" :key="index" style="border: 1px solid #9db0c6;" class="mb-2">
              <v-expansion-panel-header class="font-weight-bold">
                <h3>Fasilitator {{ index +1 }}</h3>
                <div class="text-right">
                  <v-btn
                    icon
                    @click.stop="confirmDelete(fasilitator.id || index)"
                  >
                    <v-icon> $delete </v-icon>
                  </v-btn>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mt-2">
                        <!-- v-bind:name="fasilitator.name" -->
                  <input-fcl
                    :data="fasilitator"
                    @update="$set(list_fasilitator, index, $event)"
                  />         
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-col cols="12">
            <v-btn outlined color="primary" @click="list_fasilitator.push({name:'',description:'',picture:''})">
              <span> <v-icon>$add</v-icon> Tambah fasilitator</span>
            </v-btn>
          </v-col>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-card-actions class="pa-4" style="background: #f8f9fa">
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" elevation="0" @click="cancel()">
        Batal
      </v-btn>
      <v-btn color="primary" elevation="0" v-if="tabSection==0 || tabSection==1" @click="tabSection++">
        Lanjut
      </v-btn>
      <v-btn color="primary" elevation="0" v-else @click="save" :loading="submitting">
        Simpan Perubahan
      </v-btn>
    </v-card-actions>

    <v-snackbar 
      top 
      v-model="snackbar.state"
      color="primary"
      outlined
    >
      <!-- :timeout="timeout" -->
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import InputFcl from "./components/Edit_fasilitator";
import { mapGetters } from "vuex"; 
 import Editor from '@tinymce/tinymce-vue'

export default {
  name: "program-edit",
  components: { 'editor':Editor , InputFcl },
  data: () => ({
    tabSection: 0,

    media: {},
    modalMedia: false,

    type_prog:
    [
      {id:'online',name:"Online"},
      {id:'offline',name:"Offline"},
      {id:'hybrid',name:"Hybrid"}
    ],

    jenjang:["Paud","Taman Kanak-Kanak","SD","SMP","SMA","Mahasiswa","Profesional","Umum"],
    studi:["Bahasa Asing/Inggris","Kuliner","Pengembangan Diri","Persiapan Kerja","Strategi Marketing","Teknologi Informasi","Pertanian & Peternakan","Lainnya"],
    kp:["Mandiri","Berorientasi Tindakan","Komunikatif","Persiapan Kerja","Strategi Marketing","Teknologi Informasi","Lainnya"],
    
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      ["blockquote", "code-block","link","image"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ["clean"] // remove formatting button
    ],
    customKompetensi:[
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline"], // toggled buttons
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      [{ list: "ordered" }],      
    ],

    panel:[],
    judul:"",
    manfaat:"",
    tujuan: "",
    jenjang_usia:"",
    bidang_studi:"",
    bidang_studi_add:"",
    kompetensi:"",
    name_kompetensi:"",
    name_studi:"",
    harga_jual:null,
    harga_coret:0,
    nama_fasilitator:'',
    desc:'',
    image:'',
    image2:'',

    title : "",
    program_benefits : "",
    fasilitator:{},
    status:"",

    detail:{},
    learning_targets : {},
    cover_kelas:{},
    list_kompetensi:[],
    list_fasilitator:[],
    link_produk:[],
    program_information:{},
    study:{},

    snackbar: {
      state: false,
      text: "",
    },
    submitting:false,
    // timeout:3000,
  }),
  computed: {
    ...mapGetters("studi", {
      studi_list: "list",
      studi_detail: "detail",
    }),
  },
  mounted() {
    let title =
      this.$route.name == "edit-program" ? "Edit Program" : "Tambah Program";
    this.$emit("page-changed", 2, {
      title,
      link: this.$route.path,
    });
    this.fetchStudi();
    this.fetchProgram();
  },
  methods: {
    fetchStudi(){
      this.$store.dispatch("studi/list").finally(() => {
        this.loading = false;
      });
    }, 
    fetchPanel(){
      this.panel = [...Array(this.list_fasilitator.length).keys()].map((k, i) => i);    
    },
    async fetchProgram(){
      await this.axios
        .get(`/program/v1/company/detail/${this.$route.params.id}`)
        .then((res) =>{
          this.detail = res.data.data;
          this.learning_targets = res.data.data.learning_targets;
          this.study = res.data.data.learning_targets.studies;
          this.list_kompetensi= res.data.data.learning_targets.competence;
          this.cover_kelas = res.data.data.program_information.cover;
          this.program_information = res.data.data.program_information;
          this.list_fasilitator = res.data.data.fasilitator;
          this.link_produk = res.data.data.program_information.selling_link;
          this.fetchPanel();
          // console.log('detail',this.list_fasilitator)
        })
    },
    confirmDelete(id) {
      let index;

      index = this.list_fasilitator.findIndex((fasilitator) => fasilitator.id == id);
      if (index == -1) {
        index = id;
      }
      this.list_fasilitator.splice(index, 1);
    },
    deleteKompetensi(id) {
      let index;

      index = this.list_kompetensi.findIndex((list_kompetensi) => list_kompetensi.id == id);
      if (index == -1) {
        index = id;
      }
      this.list_kompetensi.splice(index, 1);
    },
    onDrop: function(e) {
        e.stopPropagation();
        e.preventDefault();
        var files = e.dataTransfer.files;
        this.createFile(files[0]);
    },
    onChange(e) {
        var files = e.target.files;
        this.cover_kelas.url='';
        this.createFile(files[0]);
        this.uploadCover(files[0]);
    },
    createFile(file) {
        if (!file.type.match('image.*')) {
          alert('Select an image');
          return;
        }
        // var img = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = function(e) {
          vm.image = e.target.result;
        }
        reader.readAsDataURL(file);
    },
    removeFile() {
      this.image = '';
    },
    uploadCover(file){
      var formData = new FormData();
      let a = file;
      console.log('img',a);

      formData.append("file", file, "cover.jpg");
      formData.append("source", "upload");
      formData.append("title", "cover kelas");

      console.log('result', formData);

      this.$store
        .dispatch("media/create", formData)
        .then((res) => {
          if (res.status == 200) {
            this.cover_kelas.id = res.data.id;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    onDrop2: function(e) {
        e.stopPropagation();
        e.preventDefault();
        var files = e.dataTransfer.files;
        this.createFile(files[0]);
    },
    onChange2(e) {
        var files = e.target.files;
        this.cover_fasilitator.url='';        
        this.createFile2(files[0]);
        this.uploadCover2(files[0]);

    },
    createFile2(file) {
        if (!file.type.match('image.*')) {
          alert('Select an image');
          return;
        }
        // var img = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = function(e) {
          vm.image2 = e.target.result;
        }
        reader.readAsDataURL(file);
    },
    uploadCover2(file){
      var formData = new FormData();
      // let a = file;

      formData.append("file", file, "fasilitator.jpg");
      formData.append("source", "upload");
      formData.append("title", "fasilitator");

      console.log('result', formData);

      this.$store
        .dispatch("media/create", formData)
        .then((res) => {
          if (res.status == 200) {
            this.cover_fasilitator.id = res.data.id;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    removeFile2() {
      this.image2 = '';
    },
    cancel() {
      this.$router.push("/program");
    },
    save() {
      this.submitting=true;
      if (this.study.title == 'Lainnya') {                
        let add={
          title:this.bidang_studi_add
        }
        this.axios
          .post(`/study/v1/create`, add)
          .then((res) =>{
            // console.log(res.data);
            let id_study= res.data.data.id;
             const data = {
              title : this.detail.title,
          type : this.detail.type,                          
              program_benefits : this.detail.program_benefits,
              learning_targets : {
                studies: {
                    id: id_study,
                    title: this.bidang_studi_add
                },
                competence:this.list_kompetensi,
              },
              program_information:{
                cover:this.cover_kelas.id,
                discount_price: this.program_information.discount_price,
                selling_price: this.program_information.selling_price,
                // selling_link: this.program_information.selling_link
                selling_link: this.link_produk,
              },
              fasilitator:this.list_fasilitator,
              status:this.detail.status
            }
            this.axios
              .put(`/program/v1/company/update/${this.$route.params.id}`, data)
              .then(() =>{
                this.submitting=false;

                  this.snackbar.text = `Successfully update program`;
                  this.snackbar.state = true;
                  setTimeout(() => {this.$router.push({name: "list-program"});},3000);
                  // this.is_change.show=false;            
                  
              })
              .catch((res) => {
                this.submitting=false;          
                if (res.response) {
                  this.snackbar.text = res.response.data.message;
                  // this.snackbar.text = res.response.data.validation;
                  this.snackbar.state = true;
                }
              });
          })
        .catch((res) => {
            this.submitting=false;          
            if (res.response) {
              this.snackbar.text = res.response.data.message;
              // this.snackbar.text = res.response.data.validation;
              this.snackbar.state = true;
            }
          });
      }else{        
        const data = {
          title : this.detail.title,
          type : this.detail.type,                  
          program_benefits : this.detail.program_benefits,
          learning_targets : {
            studies: {
                id:this.study.id,
                title: this.study.title
            },
            competence:this.list_kompetensi,
          },
          program_information:{
            cover:this.cover_kelas.id,
            discount_price: this.program_information.discount_price,
            selling_price: this.program_information.selling_price,
            selling_link: this.link_produk,
            // selling_link: this.program_information.selling_link
          },
          fasilitator:this.list_fasilitator,
          status:this.detail.status
        }
        this.axios
          .put(`/program/v1/company/update/${this.$route.params.id}`, data)
          .then(() =>{
            this.submitting=false;

              this.snackbar.text = `Successfully update program`;
              this.snackbar.state = true;
              setTimeout(() => {this.$router.push({name: "list-program"});},3000);
              // this.is_change.show=false;            
              
          })
          .catch((res) => {
            this.submitting=false;          
            if (res.response) {
              this.snackbar.text = res.response.data.message;
              // this.snackbar.text = res.response.data.validation;
              this.snackbar.state = true;
            }
          });
      }

      // if (!this.tabSection) {
      //   return (this.tabSection = 1);
      // }

      // if (this.$route.name == "section-add") {
      //   return this.$router.push({
      //     name: "section-list",
      //     params: {
      //       paketId: this.$route.params.paketId,
      //       data: {
      //         id: url.get_id(),
      //         section: this.section,
      //         total: 0,
      //         part: this.part,
      //         category: this.kategori,
      //       },
      //     },
      //     // name: "section-detail",
      //     // params: {
      //     //   paketId: this.$route.params.paketId,
      //     //   sectionId,
      //     // },
      //   });
      // }

      // this.$router.push({
      //   name: "list-program"
      // });
    },
  },
};
</script>
<style>

</style>