export default {
  admin_login: '/users/v1/company/login',
  admin_detail: '/users/v1/company/detail',

  // program
  program_list: '/program/v1/company/list',
  program_create: '/program/v1/company/create',
  program_detail: '/program/v1/company/detail/{id}',
  program_update: '/program/v1/company/update/{id}',

  //media
  media_upload: '/storage/v1/media/company/upload',

  //studi
  studi_list: '/study/v1/list',
  studi_create: '/study/v1/create',
  studi_detail: '/study/v1/detail/{id}',
  studi_update: '/study/v1/update/{id}',

  //user
  user_list: '/program/v1/company/list-member-redeem/{id}',
  user_detail: '/study/v1/detail/{id}',

  //presensi
  presence_list:'/presence/v1/company/list',

  // Activity
  activity_list: (payload) => `/v1/activity/${payload.program}/${payload.topic}/list`,
  activity_create: (payload) => `/v1/activity/${payload.program}/${payload.topic}/create`,
  activity_show: (payload) => `/v1/activity/${payload.program}/${payload.topic}/${payload.id}/show`,
  activity_update: (payload) => `/v1/activity/${payload.program}/${payload.topic}/${payload.id}/update`,
  activity_reorder: (payload) => `/v1/activity/${payload.program}/${payload.topic}/reorder`,
  activity_approve: (payload) => `/v1/activity/${payload.program}/${payload.topic}/${payload.id}/approve`,
  activity_delete: (payload) => `/v1/activity/${payload.program}/${payload.topic}/${payload.id}/delete`,
  
  // packet
  packet_list: (payload) => `/v1/packet/${payload.program}/${payload.topic}/${payload.activity}/list`,
  packet_create: (payload) => `/v1/packet/${payload.program}/${payload.topic}/${payload.activity}/create`,
  packet_show: (payload) => `/v1/packet/${payload.program}/${payload.topic}/${payload.activity}/${payload.id}/show`,
  packet_update: (payload) => `/v1/packet/${payload.program}/${payload.topic}/${payload.activity}/${payload.id}/update`,
  packet_approve: (payload) => `/v1/packet/${payload.program}/${payload.topic}/${payload.activity}/${payload.id}/approve`,
  packet_delete: (payload) => `/v1/packet/${payload.program}/${payload.topic}/${payload.activity}/${payload.id}/delete`,
  
  // group
  group_list: (payload) => `/v1/group/${payload.program}/${payload.topic}/${payload.activity}/${payload.packet}/list`,
  group_create: (payload) => `/v1/group/${payload.program}/${payload.topic}/${payload.activity}/${payload.packet}/create`,
  group_show: (payload) => `/v1/group/${payload.program}/${payload.topic}/${payload.activity}/${payload.packet}/${payload.id}/show`,
  group_update: (payload) => `/v1/group/${payload.program}/${payload.topic}/${payload.activity}/${payload.packet}/${payload.id}/update`,
  group_approve: (payload) => `/v1/group/${payload.program}/${payload.topic}/${payload.activity}/${payload.packet}/${payload.id}/approve`,
  group_delete: (payload) => `/v1/group/${payload.program}/${payload.topic}/${payload.activity}/${payload.packet}/${payload.id}/delete`,
}